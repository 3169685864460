import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, Table, Row, Col } from "reactstrap";
import axios from "axios";
const H5pData = (props) => {
  const parseTime = (seconds) => {
    var date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  };

  const [h5pData, setH5pData] = useState([]);
  const [userNiceName, setUserNiceName] = useState([]);
  useEffect(async () => {
    const response = await axios.get(
      "https://data.stage.mimshakup.com/get-results"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-students-distribution",
    //   { params: { startUnix: startUnixTime, endUnix: endUnixTime } }
    // );
    console.log(response.data);
    setH5pData(response.data);
  }, []);

  useEffect(async () => {
    const response = await axios.get(
      "https://data.stage.mimshakup.com/get-user-nicename"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-students-distribution",
    //   { params: { startUnix: startUnixTime, endUnix: endUnixTime } }
    // );
    let user_nicename =
      response.data[0].user_nicename.charAt(0).toUpperCase() +
      response.data[0].user_nicename.slice(1);
    setUserNiceName(user_nicename);
  }, []);

  return (
    <Row className="mt-5">
      <Col className="mb-5 mb-xl-0" xl="8">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Evaluation of student: {userNiceName}</h3>
              </div>
              <div className="col text-right">
                {/* <Button
                  color="primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  See all
                </Button> */}
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">User Id</th>
                <th scope="col">h5p Id</th>
                <th scope="col">Score</th>
                <th scope="col">Max Score</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {h5pData.map((data) => {
                return (
                  <tr key={data.content_id}>
                    <th scope="row">{data.user_id}</th>
                    <td>{data.content_id}</td>
                    <td>{data.score}</td>
                    <td>{data.max_score}</td>
                    <td>{parseTime(data.finished - data.opened)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default H5pData;
