import React, { useState, useEffect } from "react";

import Chart from "chart.js";

import { chartOptions, parseOptions } from "variables/charts.js";

import H5pData from "../components/Data-Analysis/H5pData";

const Index = (props) => {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      {/* <HeaderHandler unixTime={unixTime}></HeaderHandler> */}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <H5pData></H5pData>
      </div>
      {/* <Container className="mt--7" fluid>
        <DatePicker handleUnix={handleUnix}></DatePicker>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <TableComp data={courses} user={false} />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <TableComp data={users} user={true} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <StudentsDistrHandler></StudentsDistrHandler>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Index;
